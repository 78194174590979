export const mentorsCPF: string[] = [
	"10332797902", // igor
	"07875895944", // fabiano prod
	"80278041086", // fabiano dev
	"06058918189", // anacarolorlandi@gmail.com
	"93323450906", // cristyanod@gmail.com
	"04982113980", // floram_m@hotmail.com
	"17748932743", // Gabrielsilvahso@hotmail.com
	"04186948933", // gustavodamschi@gmail.com
	"10828297908", // torresangenot@gmail.com
	"00608222950", // jaison.scheel@gmail.com
	"05159721932", // juliadesousarodrigues1@gmail.com
	"05154890930", // maka@makabranding.com
	"01734901900", // lucia.cristiana23@gmail.com
	"03592534904", // freitas@txm-methods.com
	"77023463900", // salodesigner@gmail.com
	"02315000920", // maite.Lemos@gmail.com
	"02514054192", // nai@txm.business
	"05142461975", // patrick@patrickveiga.com
	"82871060010", // priscila.zavadil@gmail.com
	"01487535074", // rafscezar@gmail.com
	"76283658920", // renatacrisantos@gmail.com
	"03690449910", // tiagomattozo@gmail.com
	"08347527946", // valeria.soares2603@gmail.com
	"04368845188", // portovictoria1@gmail.com
	"02330780966", // kellyanselmo1979@gmail.com
	"00817173005", // arnorocha@yahoo.com.br
	"00398541086", // analuizalisboa0803@gmail.com
	"00519088166", // nielyg@gmail.com
	"10685646440", // rhylari.santos@ics.ufal.br
	"08459285979", // tatianergalkowski@gmail.com
	"12272188931", //joanamanduca19203@gmail.com
	"77023463900", // Salomão
	"03592534904", // Freitas
	"06029239996", // fermiano1988@gmail.com
	"93997949034", // ft_oliveira@hotmail.com
];
